import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  color: #333;
  background-color: #fff;
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 100vw;
`;

const SocialCard = styled.a`
  flex: 1;
  text-align: center;
  margin-left: 5px;
  padding: 0.5em;
  text-decoration: none;
  white-space: nowrap;
  color: white;
  font-family: var(--font-family);
  white-space: nowrap;
  font-size: 16px;
  font-weight: 550;
  border-radius: 2px;
  &.facebook {
    background-color: #306199 !important;
  }
  &.email {
    background-color: #0a88ff !important;
  }
  &.linkedin {
    background-color: #007bb6 !important;
  }
  &.twitter {
    background-color: #26c4f1 !important;
  }
  @media (max-width: 500px) {
    .button-text {
      display: none;
    }
  }
`;

const Text = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.428;
  font-family: var(--font-family) !important;
  gap: 0.3rem;
  flex-wrap: nowrap;
  color: #8a8a8d;
  a {
    text-decoration: none;
    font-weight: bold;
    color: #8a8a8d;
    

    &:visited {
      color: #8a8a8d;
    }
  }
`;

const Footer = () => {
  return (
    <>
      <Wrapper>
        <Text>Share this with someone you know:</Text>
        <BtnContainer>
          <SocialCard
            className="email"
            href="mailto:?subject=Know%20Debt.%20Because%20you%20need%20to%20know%20debt%20to%20no%20debt.&amp;body=http://nodebtnl.ca/"
          >
            <FontAwesomeIcon icon={faAt} />
            <span className="button-text">Email</span>
          </SocialCard>
          <SocialCard
            className="facebook"
            href="https://www.facebook.com/sharer/sharer.php?u=http://nodebtnl.ca/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
            <span className="button-text">Facebook</span>
          </SocialCard>
          <SocialCard
            className="linkedin"
            href="https://www.linkedin.com/sharing/share-offsite/?url=http%3A%2F%2Fnodebtnl.ca%2F"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
            <span className="button-text">LinkedIn</span>
          </SocialCard>
          <SocialCard
            className="twitter"
            href="https://twitter.com/intent/tweet?url=http%3A%2F%2Fnodebtnl.ca%2F&text=%27Know%20Debt.%20Because%20you%20need%20to%20know%20debt%20to%20no%20debt.%27"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
            <span className="button-text">Twitter</span>
          </SocialCard>
        </BtnContainer>
        <Text>
          Return to{" "}
          <a href="https://janesnoseworthy.ca/" target="_blank" rel="noreferrer">
            Janes Noseworthy
          </a>{" "}
          Website
        </Text>
        <Text><a href="tel: +1-800-563-9779">Call us: 1-800-563-9779</a></Text>
      </Wrapper>
    </>
  );
};

export default Footer;
