import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { CurrentUserContext } from "../../../CurrentUserContext";
import { NavLink } from "react-router-dom";

let data = require("../../../retiredcouple.json");
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: fixed repeat;
  background-image: url("/bg-pattern.jpg") !important;
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const QuizBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
  margin: 4rem 0rem;
  overflow-y: scroll;
  height: 60vh;
  background-color: transparent;
  @media (max-width: 1300px) {
    width: 100vw;
    height: 50vh;
  }
  @media (max-width: 500px) {
    width: 100vw;
    height: 70vh;
    gap: 4rem;
  }

  * {
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
const QuestionWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: min-content;
  max-width: 40vw;
  flex-wrap: nowrap;
  margin-bottom: 5rem;
  max-height: 30vh;
  position: inherit;
  text-align: left;
  @media (max-width: 500px) {
    margin-top: 3rem;
  }
  @media (max-width: 375px) {
    margin-top: 5rem;
  }
`;

const StyledButton = styled.button`
  display: inline;
  align-items: center;
  justify-content: left;
  background-color: transparent;
  width: 40vw;
  border-radius: 10px;
  border: 1px solid #fff;
  text-align: left;
  padding: 0.8rem;
  color: white;
  font-family: var(--font-family);
  font-size: 1rem;
  @media (max-width: 1300px) {
    width: 80vw;
  }
  @media (max-width: 800px) {
    width: 100vw;
  }
  @media (max-width: 375px) {
    min-height: fit-content;
  }
  &:focus {
    background-color: #a7d164;
  }
  -webkit-tap-highlight-color: #a7d164;
  &:hover {
    box-shadow: inset 0 0 0 1.5px black;
    background-color: #a7d164;
  }
`;

const QuestionText = styled.h1`
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 40vw;
  border-radius: 10px;
  margin: 2.6rem 0rem 0rem 0rem;
  text-align: center;
  color: white;
  font-family: var(--font-family);
  font-size: 1.5rem;
  min-height: 3rem;
  @media (max-width: 1300px) {
    width: 80vw;
    height: auto;
  }
  @media (max-width: 700px) {
    width: 100vw;
  }
`;

const SubmitButton = styled.button`
  color: white;
  border: 2px solid white;
  background-color: transparent;
  border-radius: 25px;

  font-size: 1.3rem;
  white-space: nowrap;
  margin: 1.5rem;
  margin-bottom: 2em;
  text-align: center;
  padding: 6px 12px;
  min-width: fit-content;
`;
const Header = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  max-width: 100%;
  white-space: wrap;
  text-align: center;
  margin-top: 2rem;
`;

const RetiredQuiz = () => {
  const { answers, setAnswers } = useContext(CurrentUserContext);
  const { bgColor, setBgColor } = useState("");
  document.addEventListener("touchstart", function () {}, false);

  useEffect(() => {
    setAnswers([]);
    // eslint-disable-next-line
  }, []);

  const handleClick = (e) => {
    setAnswers([...answers, e.target.id]);
  };

  const scrollToElement = (ele) => {
    ele.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Wrapper>
      <Header>
        Take our short quiz to see if you need help with your finances.
      </Header>
      <QuizBox id="quizbox">
        {data.map((e, questionIndex) => {
          return (
            <>
              <QuestionWrapper id={`question${questionIndex}`} key={e.question}>
                <QuestionText key={e.question}>
                  {questionIndex + 1} / {data.length}: {e.question}
                </QuestionText>
                {e.children.map((answer, answerIndex) => {
                  return (
                    <StyledButton
                      id={answerIndex}
                      style={{ backgroundColor: bgColor }}
                      onClick={(event) => {
                        handleClick(event);
                        scrollToElement(
                          document.getElementById(
                            `question${questionIndex + 1}`
                          )
                        );
                        setBgColor("#a7d164");
                      }}
                      key={answer}
                    >
                      {answer}
                    </StyledButton>
                  );
                })}
              </QuestionWrapper>
            </>
          );
        })}
        <NavLink
          to={answers.length === 0 ? "/home" : "/retired-couple/results"}
          style={{ textDecoration: "none" }}
        >
          <SubmitButton>Submit</SubmitButton>
        </NavLink>
      </QuizBox>
    </Wrapper>
  );
};

export default RetiredQuiz;
