import React from "react";
import styled from "styled-components";
import OnboardingBlock from "./survey/OnboardingBlock";
let data = require("../survey.json");

const Wrapper = styled.div`

`;

const Home = () => {
  return (
    <>
      <Wrapper>
        <OnboardingBlock data={data} />
      </Wrapper>
    </>
  );
};

export default Home;
