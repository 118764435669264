import React from "react";
import Home from "./Components/Home";
import ProfileCardSingle from "./Components/survey/Single/ProfileCardSingle";
import ProfileCardRetired from "./Components/survey/Retired/ProfileCardRetired";
import ProfileCardFamily from "./Components/survey/family/ProfileCardFamily";
import ProfileCardSinglePerson from "./Components/survey/single-person/SinglePersonProfileCard";
import SingleQuiz from "./Components/survey/Single/SingleQuiz";
import RetiredQuiz from "./Components/survey/Retired/RetiredQuiz";
import FamilyQuiz from "./Components/survey/family/FamilyQuiz";
import SinglePersonQuiz from "./Components/survey/single-person/SinglePersonQuiz";
import RetiredResults from "./Components/survey/Retired/RetiredResults";
import FamilyResults from "./Components/survey/family/FamilyResults";
import SingleResults from "./Components/survey/Single/SingleResults";
import SinglePersonResults from "./Components/survey/single-person/SinglePersonResults";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
// import Footer from "./Components/Footer";
import styled from "styled-components";
import GlobalStyle from "./Components/GlobalStyle";
import data from "./survey.json";

const Wrapper = styled.div`
  width: 100vw;
  position: absolute;
  left: 0;

  justify-content: center;
  align-items: center;
  background: fixed repeat;
  background-image: url("/bg-pattern.jpg") !important;
`;

function App() {
  const history = useHistory();

  return (
    <>
      <BrowserRouter history={history}>
        <GlobalStyle />
        <Header />
        <Switch>
          <Wrapper className="">
            <Route path="/" exact>
              <Redirect to="/home" />
            </Route>
            <Route path="/home">
              <Home />
            </Route>

            <Route path="/survey/single-parent">
              <ProfileCardSingle data={data} />
            </Route>
            <Route path="/single-parent/survey/quiz">
              <SingleQuiz />
            </Route>
            <Route path="/survey/retired-couple">
              <ProfileCardRetired data={data} />
            </Route>
            <Route path="/retired-couple/survey/quiz">
              <RetiredQuiz />
            </Route>
            <Route path="/retired-couple/results">
              <RetiredResults />
            </Route>
            <Route path="/survey/family">
              <ProfileCardFamily data={data} />
            </Route>
            <Route path="/family/survey/quiz">
              <FamilyQuiz />
            </Route>
            <Route path="/survey/single-person">
              <ProfileCardSinglePerson data={data} />
            </Route>
            <Route path="/family/results">
              <FamilyResults />
            </Route>
            <Route path="/single-person/survey/quiz">
              <SinglePersonQuiz />
            </Route>
            <Route path="/single-person/results">
              <SinglePersonResults />
            </Route>
            <Route path="/single-parent/results">
              <SingleResults />
            </Route>
            <Footer />
          </Wrapper>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
