import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from 'react-responsive-carousel/lib/js/components/Carousel/index';


const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: dodgerblue;
  border-radius: 10px;
  font-family: var(--font-family);

  color: white;
  width: 20vw;
  padding: 1rem;
  height: 40vh;
  &:hover {
    box-shadow: inset 0 0 0 1.5px black;
    background-color: #a7d164;
  }
  @media (max-width: 1200px) {
   height: auto;
    width: 50vw;
    text-align: center;
  }
  @media (max-width: 800px) {
   height: auto;
    width: 60vw;
    
`;
const ImageWrapper = styled.div`
  display: flex;

  padding-bottom: 2em;
  @media (max-width: 500px) {
    padding: 0;
  }
`;
const OptionText = styled.h3`
  color: white;
  font-size: 2em;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 1.5em;
  }
`;

const Image = styled.img`
  width: 4.5rem;
  height: 9rem;
  object-fit: contain;
`;

const UserSelect = ({ option }) => {
  
  return (
    <>
      <Card name="card">
        <ImageWrapper>
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            autoPlay={true}
            transitionTime={1000}
            swipable={false}
            infiniteLoop={true}
            interval={8000}
            animation="fade"
          >
            {option.images.map((pic, index) => {
              return (
                <>
                  <Image src={pic.src} key={index} />
                </>
              );
            })}
          </Carousel>
        </ImageWrapper>
        <OptionText key={option.name}>{option.name}</OptionText>
      </Card>
    </>
  );
};

export default UserSelect;
