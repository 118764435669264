import React, { useState, createContext } from "react";

export const CurrentUserContext = createContext({});

export const CurrentUserProvider = ({ children }) => {
const [user, setUser] = useState([])
const [answers, setAnswers] = useState([])



  return (
    <CurrentUserContext.Provider
      value={{
        user,
        answers,
        setAnswers,
        setUser
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};