import React from "react";
import styled from "styled-components";
// import { CurrentUserContext } from "../../../CurrentUserContext";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import StartQuizButton from "../../Common/StartQuizButton";

const Wrapper = styled.div`
  display: flex;
  background: fixed repeat;
  background-image: url("/bg-pattern.jpg") !important;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  width: 100vw;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: dodgerblue;
  border-radius: 10px;
  color: white;
  width: 20vw;
  font-family: var(--font-family);
  padding: 1rem;
  max-height: 40vh;
  &:hover {
    box-shadow: inset 0 0 0 1.5px black;
    background-color: #a7d164;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 700px) {
    height: 30vh;
    width: 40vw;
    text-align: center;
  }
`;
const ImageWrapper = styled.div`
  display: flex;

  padding-bottom: 2em;
  @media (max-width: 500px) {
    padding: 0;
  }
`;
const OptionText = styled.h3`
  color: white;
  font-size: 1.6em;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 1em;
  }
`;
const Image = styled.img`
  width: 4.5rem;
  height: 9rem;
  object-fit: contain;
`;

const CopyContainer = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  max-width: 30vw;
  max-height: 80vh;
  justify-content: center;
  align-items: center;
  line-height: 1.65rem;
  color: white;
  text-align: center;
  font-family: var(--font-family);
  @media (max-width: 700px) {
    height: fit-content;
    max-width: 90vw;
  }
`;

const ProfileCardSingle = ({ data }) => {
  // const { user, setUser } = useContext(CurrentUserContext);
  const userData = data;
  const url = "single-parent";

  return (
    <Wrapper>
      <Card name="card">
        <a href="/home">
          <ImageWrapper>
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              showIndicators={false}
              autoPlay={true}
              transitionTime={1000}
              swipable={false}
              infiniteLoop={true}
              interval={8000}
            >
              <Image src={`/${userData[0].images[0].src}`} alt="picture of a single parent with their child" />
              <Image src={`/${userData[0].images[1].src}`} alt="picture of a single parent with their child" />
              <Image src={`/${userData[0].images[2].src}`} alt="picture of a single parent with their child" />
              <Image src={`/${userData[0].images[3].src}`} alt="picture of a single parent with their child" />
            </Carousel>
          </ImageWrapper>
          <OptionText key={userData[0].name}>{userData[0].name}</OptionText>
        </a>
      </Card>
      <CopyContainer>
        <p>
          I'm a single parent. I've got a full-time job and I'm just trying to
          make ends meet and get my kids to their activities on time.{" "}
        </p>
        <br />
        <p>
          I've got my mortgage and car payments, monthly bills, credit card
          debt, old student loans, and those who depend on me to put food on the
          table.{" "}
        </p>
        <br />

        <p>
          Last week creditors started calling. I think it might be time to get
          some advice.
        </p>
        <StartQuizButton url={url} />
      </CopyContainer>
    </Wrapper>
  );
};

export default ProfileCardSingle;
