import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import UserSelect from "./UserSelect";

const Wrapper = styled.div`
  align-items: center;
  min-width: 100vw;
  margin: 0 auto;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  overflow-x: hidden;
  padding: 3.5rem;
  gap: 1rem;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0rem 1rem;
  flex-wrap: wrap;
  @media (max-width: 758px) {
    /* flex-direction: column; */
  }
`;
const Intro = styled.h1`
  color: #a7d164;
  font-size: 2rem;
  font-weight: bold;
  font-family: var(--font-family);

  white-space: nowrap;
`;
const PlainHeader = styled.h1`
  font-family: var(--font-family);
  white-space: wrap;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  overflow-wrap: break-word;
`;

const CardWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const CardGrid = styled.div`
  display: flex;
  gap: 1em;
  width: 80vw;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const OnboardingBlock = (data) => {
  return (
    <>
      <Wrapper>
        <HeaderWrapper>
          <Intro>Get Started:</Intro>
          <PlainHeader>Choose the option that fits you best:</PlainHeader>
        </HeaderWrapper>
        <CardGrid>
          {data.data.map((option, index) => {
            return (
              <CardWrapper to={`/survey/${option.code}`} key={option.code}>
                <UserSelect key={option.name} option={option} />
              </CardWrapper>
            );
          })}
        </CardGrid>
      </Wrapper>
    </>
  );
};

export default OnboardingBlock;
