import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StartQuiz = styled(Link)`
  color: white;
  background-color: #a7d164;
  justify-content: center;
  align-items: center;
  text-align:center;
  display: flex;
  height: 3rem;
  max-width: 30vw;
  min-width: 25vw;
  margin: 10px;
  
  border-radius: 5px;
  margin: 10px;
  text-decoration: none;
  font-family: var(--font-family);
  &:active{
    background-color: dodgerblue;
  }
@media(max-width: 700px){
  min-width: 80vw;
}
`;

const StartQuizButton = ({url}) => {
  return <StartQuiz to={`/${url}/survey/quiz`}>Start Quiz</StartQuiz>;
};

export default StartQuizButton;
