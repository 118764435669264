import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
  max-width: 100vw;
  font-family: var(--font-family);

  @media (max-width: 850px) {
    padding: 0;
    flex-direction: column;
  }
  a {
    text-decoration: none;
  }
`;

const StyledLogo = styled.img`
   max-width: 100%;
  max-height: 100%;
  width: auto;

  display: block;
  margin: 0 auto;

`;

const ImgCtn = styled.span`
display: flex;
gap: 5rem;
width: 100%;
justify-content: space-between;
align-items: center;
  padding: 0.6rem;
  a {
    text-decoration: none;
    padding: 2rem;
  }
  @media(max-width: 700px){
    flex-direction: column;
    gap: 1rem;
    max-width:100%;
  }
  .nodebt{
    padding-left: 6rem;
    
  }
  .janeslogo{
    max-width: 40%;
    align-content: flex-end;
    padding-right: 1rem;
    margin-right: 0;
  }

  img{
    max-width: 100%;
  }
  span{
    text-decoration: none;
    color: gray;
    font-style: italic;
    white-space: nowrap;
  }
 
  @media(max-width: 1000px){
    .janeslogo{
      min-width: 100%;
    }
    .nodebt{
      padding: 0;
    }
  }

`;

const Header = () => {
  return (
    <Wrapper>
      <ImgCtn>
        <a className="nodebt" href="/home#">
          <StyledLogo src="/logo.png" />
          <span>They no debt, and now ... we have no debt.</span>
        </a>
        <a href="/home#">
          <StyledLogo top="#" src="/jn-real-logo.png" className="janeslogo" />
        </a>
      </ImgCtn>
    </Wrapper>
  );
};

export default Header;
