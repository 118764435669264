import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { CurrentUserContext } from "../../../CurrentUserContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: fixed repeat;
  background-image: url("/bg-pattern.jpg") !important;
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const ResultCtn = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const ResultCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem;
  align-items: center;
  width: 33%;
  height: 33%;
  gap: 1rem;
  color: white;
  @media (max-width: 1200px) {
    width: 100vw;
  }
`;
const Heading = styled.h2`
  color: white;
  text-align: center;
  white-space: nowrap;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 2.4rem;
  padding: 3rem 0rem 0rem 0rem;
  @media (max-width: 765px) {
  }
`;

const ResultText = styled.div`
  color: white;
  position: relative;
  font-family: var(--font-family);
  margin-top: 2rem;
  line-height: 2rem;
  padding: 2rem;
  font-size: 20px;
  @media (max-width: 765px) {
  }
`;
const Mostlies = styled.div`
  display: flex;
  height: 5rem;
  font-weight: 400;
  font-family: var(--font-family);
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  @media (max-width: 1200px) {
    width: 80vw;
  }
`;

const Percentages = styled.h1`
  font-weight: bold;
  font-size: 4rem;
  font-family: var(--font-family);
`;

const GetInTouchButton = styled.a`
  color: #a7d164;
  border: 2px solid #a7d164;
  background-color: transparent;
  border-radius: 25px;
  font-family: var(--font-family);
  text-decoration: none;
  font-size: 2rem;
  white-space: nowrap;
  margin: 10rem 0rem 4rem 0rem;
  text-align: center;
  padding: 6px 12px;
`;
const FamilyResults = () => {
  const { answers } = useContext(CurrentUserContext);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const mostFrequent = (arr) => {
    let maxCount = 0;
    const occurrences = new Map();
    arr.forEach((x) => {
      const count = occurrences.has(x) ? occurrences.get(x) + 1 : 1;
      occurrences.set(x, count);
      maxCount = count > maxCount ? count : maxCount;
    });
    return Array.from(occurrences).find(
      ([element, count]) => count === maxCount
    )[0];
  };

  let largestNumber = mostFrequent(answers);
  let data = {};
  answers.forEach((el) => {
    if (!data[el]) {
      return (data[el] =
        (answers.filter((ob) => ob === el).length * 100) / answers.length);
    }
  });

  return (
    <>
      {" "}
      {data.length === 0 ? (
        <div>loading...</div>
      ) : (
        <Wrapper>
          <Heading className="heading">Your Results</Heading>
          <ResultCtn>
            <ResultCol
              style={
                largestNumber === document.getElementById(0).id
                  ? { color: "#a6d064" }
                  : { color: "#858588" }
              }
            >
              <Percentages id="0">
                {data && data[0] ? (
                  `${parseFloat(data[0].toFixed(0))}%`
                ) : (
                  <Percentages>0%</Percentages>
                )}
              </Percentages>
              <Mostlies
                style={
                  largestNumber === document.getElementById(0).id
                    ? { borderColor: "#a6d064", color: "white" }
                    : { borderColor: "#858588", color: "#858588" }
                }
              >
                Mostly A's
              </Mostlies>
              <ResultText>
                You're doing well. You aren't deep in debt and your family feels
                secure. But if you have any questions, give us a call!
              </ResultText>
            </ResultCol>
            <ResultCol
              style={
                largestNumber === document.getElementById(1).id
                  ? { color: "#a6d064" }
                  : { color: "#858588" }
              }
            >
              <Percentages id="1">
                {data && data[1] ? (
                  `${parseFloat(data[1].toFixed(0))}%`
                ) : (
                  <Percentages>0%</Percentages>
                )}
              </Percentages>
              <Mostlies
                style={
                  largestNumber === document.getElementById(1).id
                    ? { borderColor: "#a6d064", color: "white" }
                    : { borderColor: "#858588", color: "#858588" }
                }
              >
                Mostly B's
              </Mostlies>

              <ResultText>
                You may not be in any big financial trouble now but you better
                be careful. If you feel like you're beginning to lose control
                and could use some advice on paying off those old loans, get in
                touch and see what we can do to make your life a little easier.
              </ResultText>
            </ResultCol>
            <ResultCol
              style={
                largestNumber === document.getElementById(2).id
                  ? { color: "#a6d064" }
                  : { color: "#858588" }
              }
            >
              {" "}
              <Percentages id="2">
                {data && data[2] ? (
                  `${parseFloat(data[2].toFixed(0))}%`
                ) : (
                  <Percentages>0%</Percentages>
                )}
              </Percentages>
              <Mostlies
                style={
                  largestNumber === document.getElementById(2).id
                    ? { borderColor: "#a6d064", color: "white" }
                    : { borderColor: "#858588", color: "#858588" }
                }
              >
                Mostly C's
              </Mostlies>
              <ResultText>
                You are struggling with your debt, you need our help and we can
                help you make a plan. Get in touch so you can go from know debt
                to no debt.
              </ResultText>
            </ResultCol>
          </ResultCtn>
          <GetInTouchButton
            href="https://janesnoseworthy.ca/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get In Touch
          </GetInTouchButton>
        </Wrapper>
      )}
    </>
  );
};

export default FamilyResults;
